import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Iframe from "react-iframe"

const Kontakt = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className="google">
        <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4637.033603631501!2d11.892388278809142!3d57.712293868346116!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2f6ff32d760fd347!2sAlbins!5e0!3m2!1sen!2sse!4v1571596287420!5m2!1sen!2sse"></Iframe>
      </div>
    </Layout>
  )
}

export default Kontakt
